















































































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Notification } from '@/models/notification-model'

@Observer
@Component({
  components: {
    avatar: () => import('@/modules/community/common/components/avatar.vue'),
  },
})
export default class NotificationItem extends Vue {
  @Prop() notification?: Notification
  @Prop() hasAction?: boolean
  wallet = walletStore

  notificationItemClick(notification) {
    if (!this.hasAction) {
      this.$router.push(`/profile/${this.getUserAccountName()}/activity/notifications#${notification.id}`)
    } else {
      if (notification.data.type === 'invitation') {
        this.$router.push(`/profile/${this.getUserAccountName()}/activity/invite`)
      } else if (notification.data.type === 'update-member-role') {
        this.$router.push(`/community-organize/dao-detail/${notification?.data.dao.id}/setting/dao-members`)
      } else if (notification.data.type === 'mention') {
        this.$router.push(`/community/post/${notification?.data.post.id}?comment=${notification.data.comment.id}`)
      } else if (notification.data.type === 'dao-membership-approved') {
        this.$router.push(`/community-organize/dao-detail/${notification?.data.dao.id}`)
      } else if (notification.data.type === 'dao-membership-rejected') {
        this.$router.push(`/community-organize/dao-detail/${notification?.data.dao.id}`)
      } else if (notification.data.type === 'user-follow') {
        this.$router.push(`/profile/${notification.from['unique_id']}/summary`)
      } else if (notification.data.type === 'like') {
        this.$router.push(`/community/post/${notification?.data.post.id}`)
      } else if (notification.data.type === 'comment') {
        this.$router.push(
          `/community/post/${notification.data.comment.post.id}?comment=${notification.data.comment.id}`
        )
      } else if (notification.data.type === 'join-dao-request') {
        this.$router.push(`/community-organize/dao-detail/${notification?.data.dao.id}/setting/review-member-requests`)
      } else if (notification.data.type === 'recruitment-apply') {
        this.$router.push(`/recruitment/posts/${notification?.data.post.id}`)
      }
    }
  }

  userIcon(notification) {
    return (
      notification.data.type === 'invitation' ||
      notification.data.type === 'dao-membership-approved' ||
      notification.data.type === 'dao-membership-rejected' ||
      notification.data.type === 'update-member-role' ||
      notification.data.type === 'mention' ||
      notification.data.type === 'join-dao-request'
    )
  }

  getUserAccountName() {
    return this.wallet.userProfile?.unique_id
  }
}
